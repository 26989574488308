
<template>
      <div class="card" >
        <template v-if="initialLoading">
          <div class="card-body">
            <app-loading>
            </app-loading>
          </div>
        </template>
        <template v-else>
          <div class="card-body p-1" id="comment-box" ref="comment_box">
            <template v-if="loading"><font-awesome-icon icon="spinner" spin></font-awesome-icon></template>
            <div class="checker" ref="checker"></div>
            <template v-for="comment in instructionComments">
                <div class="row mx-2 my-1" :class="{
                  'justify-content-start': user.id != comment.createdById,
                  'justify-content-end': user.id == comment.createdById,
              }">
                  <div class="col-6">
                    <div class="card" :class="{
                   'alert-secondary': user.id != comment.createdById,
                  'alert-info': user.id == comment.createdById,
                  }">
                      <p class="my-0 px-2 py-2 text-left font-weight-bold">{{comment.createdBy}}</p>
                      <div class="card-body px-3 py-1 text-left">
                        <div v-html="translateCommentIntoHtml(comment.comment)"></div>
                        <div>
                          <p class="text-right mb-0">{{comment.created_at}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
          </div>
          <div class="card-footer">
            <template v-if="$route.name === 'IaCompanyInstructions'">
              <template v-if="!vesselResponse.cancelled_at">
                <form @submit.prevent="sendComment">
                  <template v-if="user.is_vessel === false">
                    <div class="row no-gutters align-items-center" v-if="isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.add-comments')">
                      <div class="col-10">
                        <textarea type="text" v-model="userComment.comment" ref="user-comment-area" class="form-control form-control-sm comment-area" placeholder="Enter Comment Here" name="" id="comment-text-area"></textarea>
                      </div>
                      <div class="col-2">
                        <button type="submit" :class="userComment.comment === '' ? 'e-btn-blue-disabled' : ''" class="e-btn e-btn-blue e-btn" :disabled="userComment.comment === ''">
                          <font-awesome-icon icon="envelope" class="mr-1 force-white-all" />Send
                        </button>
                      </div>
                    </div>
                  </template>
                </form>
              </template>
              <template v-else>
                <p class="text-center font-weight-bold my-0">You can't comment right now <br> This company instruction has been cancelled</p>
              </template>
            </template>
            <template v-else>
              <template v-if="$route.query.type !== 'ia_oe'">
                <template v-if="!vesselResponse.cancelled_at">
                  <form @submit.prevent="sendComment">
                    <div class="row no-gutters align-items-center">
                      <div class="col-10">
                        <textarea type="text" v-model="userComment.comment" ref="user-comment-area" class="form-control form-control-sm comment-area" placeholder="Enter Comment Here" name="" id="comment-text-area"></textarea>
                      </div>
                      <div class="col-2">
                        <button type="submit" :class="userComment.comment === '' ? 'e-btn-blue-disabled' : ''" class="e-btn e-btn-blue e-btn">
                          <font-awesome-icon icon="envelope" class="mr-1 force-white-all" />Send
                        </button>
                      </div>
                    </div>
                  </form>
                </template>
                <template v-else>
                  <p class="text-center font-weight-bold my-0">You can't comment right now <br> This company instruction has been cancelled</p>
                </template>
              </template>
              <template v-else-if="$route.query.type === 'ia_oe'">
                <template v-if="!vesselResponse.cancelled_at">
                  <form @submit.prevent="sendComment">
                    <template v-if="user.is_vessel === false">
                      <div class="row no-gutters align-items-center" v-if="isAllowed('vessel-ia.appointment.non-conformity.objective-evidence.ci.add-comments')">
                        <div class="col-10">
                          <input type="text" v-model="userComment.comment" class="form-control form-control-sm" placeholder="Enter Comment Here" name="" id="">
                        </div>
                        <div class="col-2">
                          <button type="submit" class="e-btn e-btn-blue e-btn" :disabled="userComment.comment === ''">
                            <font-awesome-icon icon="envelope" class="mr-1 force-white-all" />Send
                          </button>
                        </div>
                      </div>
                    </template>
                  </form>
                </template>
                <template v-else>
                  <p class="text-center font-weight-bold my-0">You can't comment right now <br> This company instruction has been cancelled</p>
                </template>
              </template>
            </template>
          </div>
        </template>
      </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLoading from "../../elements/AppLoading.vue";
import {permissionMixin} from "@/mixins/permissionMixins";
import jQuery from "jquery";

let $ = jQuery
  export default {
    name:'CIComment',
    components: {AppLoading},
    mixins: [permissionMixin],
    props:{
      ciVesselId:{
        type:Number,
        default:null,
      },
      vesselResponse:{
        type:Object|Array,
        default: null
      }
    },
    data(){
      return {
        params:{
          ci_vessel_id:this.ciVesselId,
          page:1,
        },
        commentObserver:null,
        initialLoading:false,
        loading:false,
        totalPage:1,
        userComment:{
          comment:'',
        },
        commentTextArea : null
      }
    },
    computed:{
      ...mapGetters(['user','instructionComments', 'openedCompanyInstruction'])
    },
    methods:{
      ...mapActions(['getVesselComments','addComment']),

      setUpIntersectionObserver(){
        let options = {
          root: this.$refs["comment_box"],
          margin: "10px",
        };
        this.commentObserver=new IntersectionObserver(this.handleIntersection,options)
        this.commentObserver.observe(this.$refs['checker'])
      },
      recordScrollPosition() {
        let node = this.$refs["comment_box"];
        this.previousScrollHeightMinusScrollTop =
          node.scrollHeight - node.scrollTop;
      },
      restoreScrollPosition() {
        let node = this.$refs["comment_box"];
        node.scrollTop =
          node.scrollHeight - this.previousScrollHeightMinusScrollTop;
      },
     async  handleIntersection([entry]){
        if(entry.isIntersecting){
          console.log('test');
        }
        if(entry.isIntersecting && this.params.page <= this.totalPage){
          await this.loadMoreComments()
        }
      },

      async loadMoreComments(){
          this.recordScrollPosition();
          this.loading=true
          const response = await this.getVesselComments(this.params)
          this.params.page++
          if(response){
            this.totalPage=response.data.last_page
            this.$nextTick(() => {
              this.restoreScrollPosition();
            });
            this.loading=false
          }
      },
      async sendComment(){
        let userComment = {
          comment : this.userComment.comment,
          ci_vessel_id : this.ciVesselId
        };

        this.commentTextArea.focus();
        const response = await this.addComment(userComment)
        if(response) {
          this.userComment.comment = '';
          let node = this.$refs["comment_box"];
          node.scrollTop = node.scrollHeight;
          setTimeout(() => {
            this.setTextAreaHeightAndScroll();
          }, 100);
        }
      },
      translateCommentIntoHtml(comment) {
        let newComment = ``;
        let splitComment = comment.split('\n');

        splitComment.forEach((comment) => {
          if (comment === '') {
            newComment += `<br>`;
          } else {
            newComment += `<div>` + comment + `</div>`;
          }
        });
        return newComment;
      },
      setTextAreaHeightAndScroll() {
        this.commentTextArea.css({
          height : 'auto'
        });
        this.commentTextArea.css({
          height : this.commentTextArea[0].scrollHeight + 'px'
        });

        if (this.commentTextArea[0].scrollHeight >= 100) {
          this.commentTextArea.css({
            overflowY : 'visible'
          });
        } else {
          this.commentTextArea.css({
            overflowY : 'hidden'
          });
        }
      },
      setCommentInputEventHandler() {
        let _this = this;
        let submitted = false;
        this.commentTextArea = $(this.$refs['user-comment-area']);

        this.commentTextArea.on('keydown', function(event) {
          if (event.keyCode === 13) {
            if (event.shiftKey === false) {
              if (_this.userComment.comment === '') {
                event.preventDefault();
              } else {
                if (submitted === false) {
                  _this.sendComment();
                  submitted = true;
                } else {
                  event.preventDefault();
                }
              }
            } else {
              submitted = false;
            }
          } else {
            submitted = false;
          }
          _this.setTextAreaHeightAndScroll();
        });

        this.commentTextArea.on('keyup', function (event) {
          if (event.keyCode === 13) {
            if (event.shiftKey === false) {
              if (_this.userComment.comment === '') {
                event.preventDefault();
              }
              // } else if (_this.userComment.comment === '\n') {
              //   _this.userComment.comment = '';
              // }
            }
          }
          _this.setTextAreaHeightAndScroll();
        });
      }
    },

    created() {
       this.initialLoading=true
       this.params.first_load=true,
       this.getVesselComments(this.params).then((response)=>{
         delete this.params.first_load
         this.initialLoading=false
         this.totalPage=response.data.last_page
         this.params.page++;
         setTimeout(()=>{
           let scrollContainer = this.$refs["comment_box"];
           scrollContainer.scrollTop = scrollContainer.scrollHeight
           this.setCommentInputEventHandler();
           setTimeout(()=>{
             this.setUpIntersectionObserver()
           },750)
         },1000)
       })
    },

    destroyed() {
      this.params.page=1
      if (this.commentObserver) {
        this.commentObserver.disconnect();
      }
    }
  }
</script>



<style scoped>
  #comment-box{
    height: 300px;
    overflow-y: scroll;
  }

  .checker{
    height: 1px;
  }

  .comment-area {
    min-height: 31px;
    resize: none;
    max-height: 100px;
    overflow-y: hidden;
  }
</style>
